//FIXME
const endPoint = 'https://backend.dev.tel-chan.com';
const endPoint2 = 'https://api.dev.tel-chan.com'
const endPointOstiaries = 'https://ostiaries.dev.tel-chan.com'

const awsconfig = {
  Auth: {
    region: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:f26df8fc-c40f-4858-8d5d-9e59ecb2d552',
    identityPoolRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_ju5DMxKsY',
    userPoolWebClientId: '71orr5is2haqmvojr2qjqap6b5',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    endpoints: [
      {
        name: 'SecureSession',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'StatusMessages',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AlertToList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'DeviceList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'RouterDeviceList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDeviceSettings',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDevice',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateRouter',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAllDevices',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetBilling',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAlertTo',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetDispatch',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDispatch',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAccount',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Purchase',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'PurchaseHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AuditHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'SendContactMail',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Unsubscribe',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AlertList',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDeviceStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ConfirmLineUser',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SaveTmpData',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ConfirmCode',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ResendConfirmCode',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'NotificationSettings',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'PriceData',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateProfile',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'EncryptPassword',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'StartPhoneNumberAuthorization',
        endpoint: endPointOstiaries,
        region: 'ap-northeast-1'
      },
      {
        name: 'CheckDeviceStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SignOut',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SensorValues',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'RouterStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'Users',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
    ]
  },
};

export{awsconfig};
