const md5 = require('md5');
const Config = {
  betacode: 'TERU3188',
  api_url_base: 'https://backend.dev.tel-chan.com',
  api_url_base2: 'https://api.dev.tel-chan.com',
  cancel_url: 'http://localhost:3000/signup?errorCode=2',
  slack_url: 'https://backend.dev.tel-chan.com/slack',
  gmo_shop_id: 'tshop00069164',
  serviceName: 'てるちゃん',
  serviceNameSubscribe: 'てるちゃん新規登録',
  docomoTimeout: 1200,
  //インボイス対応
  companyName: '株式会社ＫＤＤＩウェブコミュニケーションズ',
  companyNumber: 'T5010001065739',
  companyName2: '株式会社ファーストビット',
  companyNumber2: 'T5010001096404',
  ostiariesMode: 'asp',
  ostiariesServiceId: 'cYQskWmS7mQ4JAFfsrTsEat6MUmXqQYxzwEJykF7'
};
export{Config};
